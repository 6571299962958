@import 'src/styles/theme.scss';
@import 'src/styles/mixins.scss';

.person-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  transition: opacity 0.2s ease, visibility 0.2s ease;

  &.hidden {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }

  .bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #222324;
    opacity: 0.9;
    z-index: 1;
  }

  .modal-content {
    width: 562px;
    min-height: 300px;
    position: relative;
    z-index: 2;
    background-color: #0c0c0c;
    border-radius: 6px;
    padding: 35px 90px 50px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    @include screen('mobile') {
      width: 80%;
      padding: 40px 10px;
    }

    .photo {
      width: 194px;
      height: 194px;
      border-radius: 3px;
      margin-bottom: 16px;
      overflow: hidden;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 0 25%;
      }
    }

    .title {
      font-family: $almoni-bold;
      color: #fff;
      font-size: 32px;
      line-height: 25px;
      letter-spacing: 0.5px;
      padding-bottom: 3px;
      text-align: center;
    }

    .subtitle {
      font-family: $almoni-demibold;
      color: #fff;
      opacity: 0.7;
      font-size: 24px;
      line-height: 22px;
      letter-spacing: 0.5px;
      padding-bottom: 30px;
      text-align: center;
    }

    .count-info {
      font-family: $almoni-light;
      color: #fff;
      font-size: 32px;
      line-height: 25px;
      letter-spacing: 0.5px;
      padding-bottom: 32px;
      text-align: center;

      @include screen('mobile') {
        font-size: 28px;
      }
    }

    .close-btn {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 24px;
      height: 24px;
      display: block;

      &:hover {
        span {
          opacity: 0.8;
        }
      }

      span {
        position: absolute;
        width: 20px;
        height: 2px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        background-color: white;

        &:first-child {
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }
    }
  }
}
