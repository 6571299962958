.shares {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  column-gap: 24px;
}
.share-btn {
  width: 40px;
  height: 40px;
  border: 1px solid transparent;
  border-radius: 2px;
  background-color: #e7333c;
  transition: background-color 0.2s ease-in-out, border 0.2s ease-in-out;

  &:hover {
    border: 1px solid #e7333c;
    background-color: rgba(255, 255, 255, 0.1);
  }
}
