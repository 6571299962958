@import 'src/styles/theme.scss';
@import 'src/styles/mixins.scss';

.main-page {
  text-align: center;
}

.page-wrapper {
  margin: 0 auto;
  width: 77vw;
  padding-top: 115px;
  padding-bottom: 130px;

  @include screen('tablet') {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }

  @include screen('mobile') {
    width: 100%;
    padding-top: 95px;
    padding-bottom: 48px;
  }
}

.fixedBg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: -1;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 26px 26px 22px 30px;
  z-index: 1;
  transition: 0.3s;
  @include screen('mobile') {
    padding: 15px 20px 10px 20px;
  }

  &.sticky {
    background-color: rgba(34, 35, 36, 1);

    .title,
    .mako-link {
      opacity: 1;
      visibility: visible;
    }
  }

  .title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: $almoni-bold;
    font-size: 52px;
    line-height: 0.95;
    color: #f0f0f0;
    opacity: 0;
    visibility: hidden;
    margin: 0;
    cursor: pointer;

    @include screen('mobile') {
      font-size: 28px;
    }
  }

  .mako-link {
    width: 119px;
    opacity: 0;
    visibility: hidden;
    @include screen('mobile') {
      width: 84px;
      padding-top: 5px;
    }
    img {
      width: 100%;
    }
  }
}

.top-section {
  position: relative;
  max-width: 990px;
  margin: 0 auto;
  text-align: center;

  .mako-link {
    display: block;
    width: 129px;
    margin: 0 auto 30px;
    transform: translateX(-20px);

    img {
      width: 100%;
    }

    @include screen('mobile') {
      width: 91px;
      margin: 0 auto 10px;
    }
  }

  .title {
    font-family: $almoni-bold;
    font-size: 185px;
    line-height: 0.83;
    color: #f0f0f0;
    margin: 0 30px 0 0;

    span {
      color: #de0000;
    }

    @include screen('mobile') {
      font-size: 97px;
      line-height: 88px;
      margin: 0;
    }
  }

  .sub-title {
    font-family: $almoni-bold;
    font-size: 50px;
    line-height: 47.75px;
    margin: 0;

    @include screen('mobile') {
      font-size: 32px;
      line-height: 30px;
    }
  }

  .text {
    font-family: 'Open Sans';
    font-size: 24px;
    line-height: 34px;
    letter-spacing: -0.2px;
    font-weight: 400;
    margin: 26px 0 10px;
    @include screen('mobile') {
      font-size: 20px;
      line-height: 25px;
      margin: 18px 12px 23px;
    }

    span {
      font-weight: 700;
    }
  }
}

.search-container {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: 675px;
  padding: 36px 0 81px;
  @include screen('mobile') {
    padding: 12px 12px 35px;
  }

  .search-wrapper {
    display: flex;
    align-items: center;
    width: 611px;
    height: 51px;

    border-radius: 2px;
    overflow: hidden;

    &.isFocused {
      border: solid 1px #de0000;
    }

    @include screen('mobile') {
      flex-grow: 1;
      margin-left: 12px;
    }
  }

  .input-wrapper {
    position: relative;
    flex-grow: 1;
    border: 1px solid #646464;
    input {
      display: block;
      width: 100%;
      height: 49px;
      line-height: 40px;
      padding: 0 12px 0 31px;
      outline: none;
      border: none;
      font-size: 16px;
      font-family: 'Open Sans';
      font-weight: 400;
      color: #7d8d96;
      background-color: rgba(255, 255, 255, 0.1);

      &::placeholder {
        color: #7d8d96;
      }
    }
    input[type='search']::-ms-clear {
      display: none;
      width: 0;
      height: 0;
    }
    input[type='search']::-ms-reveal {
      display: none;
      width: 0;
      height: 0;
    }
    /* clears the ‘X’ from Chrome */
    input[type='search']::-webkit-search-decoration,
    input[type='search']::-webkit-search-cancel-button,
    input[type='search']::-webkit-search-results-button,
    input[type='search']::-webkit-search-results-decoration {
      display: none;
    }

    .clear-btn {
      position: absolute;
      width: 18px;
      height: 18px;
      left: 10px;
      top: 50%;
      transform: translate(0, -50%);
      cursor: pointer;
      &:before,
      &:after {
        content: '';
        position: absolute;
        width: 18px;
        height: 1px;
        top: 50%;
        left: 50%;
        background-color: #7d8d96;
      }

      &:before {
        transform: translate(-50%, -50%) rotate(45deg);
      }
      &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }

  .search-btn {
    position: relative;
    width: 48px;
    height: 100%;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.3s;
    background-color: #de0000;
    .search-icon {
      path {
        fill: white;
      }
    }
    // &:after {
    //   content: '';
    //   position: absolute;
    //   width: 1px;
    //   height: 39px;
    //   right: 0;
    //   top: 50%;
    //   transform: translate(0, -50%);
    //   background-color: #de0000;
    // }
    // &:hover {
    //   background-color: #de0000;
    //   .search-icon {
    //     path {
    //       fill: white;
    //     }
    //   }
    // }
    &.disabled {
      cursor: inherit;
      pointer-events: none;
    }

    .search-icon {
      transition: 0.3s;
    }
  }

  .advanced-search-btn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 51px;
    border: solid 1px transparent;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 2px;
    cursor: pointer;

    @include screen('mobile') {
      flex-shrink: 0;
    }

    &:after {
      content: '';
      position: absolute;
      left: 11.5px;
      top: 14px;
      width: 6px;
      height: 6px;
      border-radius: 3px;
      background-color: rgba(222, 0, 0, 1);
      opacity: 0;
    }

    &.active {
      border: solid 1px rgba(222, 0, 0, 1);
      &:after {
        opacity: 1;
      }
    }

    .img {
      display: block;
      width: 18px;
    }
  }
}

.sorting {
  position: relative;
  padding-bottom: 25px;
  @include screen('mobile') {
    padding: 0px 13px 13px;
  }

  .sort-btn {
    font-family: 'Open Sans';
    font-size: 16px;
    font-weight: 700;
    padding: 12px 20px;
    border: 1px solid #fff;
    background-color: #2b2c2d;
    padding: 12px 11px 12px 13px;
    transition: 0.3s;

    &:hover {
      background-color: #de000033;
      border: 1px solid #fff;
    }

    &.active {
      background-color: #de0000;
      border: 1px solid #de0000;
    }

    &.AZSorting {
      border-right: 0;
      border-radius: 2px 0px 0px 2px;
    }
    &.reverseSorting {
      border-left: 0;
      border-radius: 0px 2px 2px 0px;
    }
  }
}

.items-grid {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: min-content 1fr;
  grid-column-gap: 15px;
  grid-row-gap: 25px;
  padding-bottom: 27px;
  @include screen('mobile') {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 12px;
    padding: 0 12px 12px;
  }
}

.btns {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 27px;
  padding-bottom: 27px;
  padding-left: 5px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  @include screen('mobile') {
    padding-top: 32px;
    padding-bottom: 30px;
  }

  .prev-btn,
  .next-btn {
    font-family: 'Open Sans';
    font-size: 20px;
    line-height: 20px;
    font-weight: 700;
    cursor: pointer;

    &.disabled {
      display: none;
    }
  }

  .prev-btn {
    position: relative;
  }

  .divider {
    width: 1px;
    height: 27px;
    margin: 0 20px;
    background-color: #d9d9d9;
    &.disabled {
      display: none;
    }
  }
}

.contact {
  font-family: 'Open Sans';
  font-size: 18px;
  line-height: 18px;
  font-weight: 700;
  padding-top: 14px;
  padding-bottom: 20px;
  text-align: center;
  transition: 0.3s;
  @include screen('mobile') {
    font-size: 16px;
    line-height: 22px;
    text-align: right;
    padding: 14px 8px 20px;
  }
  &:hover {
    opacity: 0.8;
  }

  .whatsapp-link {
    display: flex;
    align-items: center;
    justify-content: center;
    @include screen('mobile') {
      max-width: 277px;
      margin: 0 auto;
    }

    img {
      width: 25px;
      height: 25px;
      margin-left: 12px;
      @include screen('mobile') {
        margin-left: 10px;
      }
    }
  }
}

.no-search-results {
  position: relative;
  padding: 32px 0;
  font-size: 40px;
  line-height: 20px;
  color: #fff;
  font-family: $almoni-demibold;
  text-align: center;
}

.accessibility-link {
  display: inline-block;
  text-align: right;
  font-family: 'Open Sans';
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  padding-top: 15px;
  color: #fff;
  transition: 0.3s;

  &:hover {
    opacity: 0.8;
  }

  @include screen('mobile') {
    display: block;
    font-size: 14px;
    text-align: center;
  }
}

@keyframes skeletonShine {
  to {
    background-position: 0 0;
  }
}
