@import 'src/styles/theme.scss';
@import 'src/styles/mixins.scss';

.video-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  visibility: visible;
  pointer-events: auto;
  transition: opacity 0.2s ease, visibility 0.2s ease;

  @include screen('mobile') {
    display: block;
  }

  &.hidden {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }

  .bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #222324;
    opacity: 0.9;
    z-index: 1;
  }

  .modal-content {
    width: 800px;
    min-height: 563px;
    position: relative;
    z-index: 2;
    background-color: #0c0c0c;
    padding: 0 0 24px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;

    @include screen('mobile') {
      max-width: 100%;
      min-height: 100%;
      padding: 72px 0 24px;
    }

    .video-container {
      position: relative;
      width: 100%;
      aspect-ratio: 3/1.69;

      @include screen('mobile') {
        max-height: 50vh;
      }
      
      video {
        width: 100%;
        // height: 100%;
        object-fit: cover;
      }
    }

    .close-btn {
      position: absolute;
      display: block;
      top: 17px;
      right: 17px;
      width: 24px;
      height: 24px;
      opacity: 0.6;
      z-index: 1;

      @include screen('mobile') {
        top: 24px;
        right: 12px;
      }

      &:hover {
        span {
          opacity: 0.8;
        }
      }

      span {
        position: absolute;
        width: 18px;
        height: 2px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        background-color: white;

        &:first-child {
          transform: translate(-50%, -50%) rotate(-45deg);
        }
      }
    }
  }
}

.buttons {
  padding-top: 49px;
  display: flex;
  align-items: center;
  gap: 20px;

  .download-btn {
    width: 40px;
    height: 40px;
    border: 1px solid transparent;
    border-radius: 2px;
    background-color: #e7333c;
    transition: background-color 0.2s ease-in-out, border 0.2s ease-in-out;
  
    &:hover {
      border: 1px solid #e7333c;
      background-color: rgba(255, 255, 255, 0.1);
    }

    img {
      margin: 8px 9px 11px;
    }
  }
}
