@import 'src/styles/theme.scss';
@import 'src/styles/mixins.scss';

.socials {
  position: relative;
  height: 100%;
  &.open {
    .toggle-btn {
      border-radius: 2px 2px 0 0;
      background-color: #de0000;

      &:after {
        content: '';
        position: absolute;
        bottom: 3px;
        left: 5px;
        right: 5px;
        width: auto;
        height: 1px;
        background-color: #fff;
      }
    }
    .btns {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @include screen('mobile') {
    width: 41px;
    // background-color: #000;
  }

  .toggle-btn {
    display: none;
    background-color: inherit;

    @include screen('mobile') {
      position: relative;
      z-index: 2;
      height: 100%;
      width: 100%;
      padding: 12px 10px;
      border-radius: 2px;
      background-color: rgba(255,255,255, 0.1);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    img {
      display: block;
    }
  }

  .btns {
    display: flex;
    align-items: center;
    gap: 20px;
    height: 100%;

    @include screen('mobile') {
      position: absolute;
      z-index: 1;
      flex-direction: column;
      gap: 8px;
      top: 100%;
      left: 0;
      width: 100%;
      height: auto;
      padding: 0 5px 5px;
      opacity: 0;
      background-color: rgba(222, 0, 0, 1);
      border-radius: 0 0 2px 2px;
      transform: translateY(-100%);
      transition: transform;
      // transition-duration: 0.35s;
    }

    button {
      display: block;
      transition: opacity;
      padding: 10px;
      border-radius: 2px;
      background-color: rgba(255,255,255, 0.1);
      transition-duration: 0.35s;
      @include screen('mobile') {
        position: relative;
        padding: 6px 4px;
        background-color: rgba(222, 0, 0, 1);

        &:after {
          content: '';
          position: absolute;
          top: -4px;
          width: 100%;
          left: 0;
          height: 1px;
          background-color: #fff;
        }
      }

      img {
        display: block;
      }

      &:hover {
        opacity: 0.7;
      }
    }
  }
}
