@import 'src/styles/theme.scss';
@import 'src/styles/mixins.scss';

.skeleton {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: min-content 1fr;
  grid-column-gap: 15px;
  grid-row-gap: 25px;
  padding-bottom: 27px;
  @include screen('mobile') {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 12px;
    padding: 0 12px 12px;
  }

  .item {
    position: relative;
    width: 100%;
    padding: 15px 15px 62px 15px;
    border-radius: 2px;
    background-color: #2b2c2d;
    transition: all ease 0.1s;
    border: solid 1px transparent;
    background-position: -500px 0;
    background-image: linear-gradient(
      135deg,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(255, 255, 255, 0.1) 20%,
      rgba(255, 255, 255, 0.2) 40%,
      rgba(255, 255, 255, 0.1) 100%
    );
    background-repeat: no-repeat;
    background-size: 1000px 100%;
    animation: skeletonShine 0.5s linear 0s infinite normal forwards;
    @include screen('tablet') {
      padding: 10px 10px 62px 10px;
    }
    @include screen('mobile') {
      padding: 8px 8px 65px 8px;
    }

    .photo {
      width: 100%;
      height: auto;
      aspect-ratio: 1 / 1;
      border-radius: 2px;
      overflow: hidden;
      transition: all ease 0.1s;
      opacity: 0;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 50% 10%;
      }
    }

    .infoWrap {
      position: relative;
      @include screen('mobile') {
        padding-bottom: 18px;
      }
    }

    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .info {
      position: relative;
      padding-top: 9px;
      padding-bottom: 20px;
      flex-grow: 1;
      transition: all ease 0.1s;
      opacity: 0;
      @include screen('mobile') {
        padding-bottom: 0;
      }

      .texts {
        padding-left: 50px;
        @include screen('mobile') {
          padding-left: 0;
          padding-right: 6px;
        }
      }

      .name {
        font-size: 35px;
        line-height: 1;
        letter-spacing: 0.5px;
        text-align: right;
        padding-bottom: 4px;

        @include screen('tablet') {
          font-size: 2vw;
        }

        @include screen('mobile') {
          font-size: 23px;
          line-height: 22px;
        }
      }

      .sub-name {
        padding-bottom: 4px;
        font-family: $almoni-demibold;
        font-size: 21px;
        line-height: 22px;
        color: #bfbfc0;
        @include screen('mobile') {
          font-size: 18px;
          line-height: 20px;
        }
      }

      .description {
        font-family: 'Open Sans';
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
        color: rgba(255, 255, 255, 0.7);
        @include screen('mobile') {
          font-size: 15px;
          line-height: 17px;
        }
      }
    }

    .icon {
      position: absolute;
      top: 21px;
      left: 6px;
      width: 43px;
      transition: all ease 0.1s;
      opacity: 0;
      @include screen('mobile') {
        // position: static;
        width: 25px;
        // margin-top: 8px;
        // margin-right: 6px;
        top: auto;
        bottom: 55px;
        right: 14px;
      }
      img {
        display: block;
        width: 100%;
      }
    }

    .button {
      position: absolute;
      left: 15px;
      right: 15px;
      bottom: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 0 13px;
      font-family: $almoni-bold;
      font-size: 22px;
      line-height: 22px;
      border: 1px solid #de0000;
      border-radius: 2px;
      transition: 0.3s;
      opacity: 0;
      @include screen('mobile') {
        font-size: 16px;
        left: 8px;
        right: 8px;
        bottom: 8px;
        padding: 7px 0 7px;

        img {
          margin-right: -7px;
          margin-left: 9px;
          margin-top: -3px;
        }
      }
      span {
        margin-right: 7px;
      }

      &:hover {
        background-color: #de0000;
      }
    }
  }

  @keyframes skeletonShine {
    to {
      background-position: 0 0;
    }
  }
}
